<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        data: []
      },
    };
  },
  props: {
    env: {
      type: Array,
      required: true,
    },
  },
  watch: {
    env: {
      handler: function (newVal) {
        // console.log('newVal-env',newVal)
        this.cdata.data = []
        if(newVal.length === {}) {
          return
        }
        newVal.forEach((item, index) => {
          this.cdata.data[index] = [item.timestamp, item.level]
        })
        // console.log(this.cdata.data)
      },
      deep: true
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {}
}
;
</script>

<style lang="scss" scoped>
</style>
