<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        data: []
      }
    };
  },
  props: {
    smell: {
      type: Object,
      required: true,
    },
  },
  watch: {
    smell: {
      handler: function (newVal) {
        // console.log(newVal)
        this.cdata.data = []
        if (Object.keys(newVal).length === 0) {
          return
        }
        newVal.smells.forEach((item, index) => {
          this.cdata.data[index] = [item.date, item.value]
        })
        // console.log(this.cdata.data)
      },
      deep: true
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
