<template>
  <div>
    <Echart
        :options="options"
        id="LeftChart"
        height="560px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          useUTC: true,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            type: 'time',
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16, // 设置字体大小
                fontWeight: 'bold'
              },
              // formatter: function (value) {
              //   var date = new Date(value);
              //   return (
              //       date.getHours()
              //   );
              // }
            },
            // axisTick: {
            //   interval: 3600 * 1000 * 2
            // },
            splitLine: {
              show: false
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16 // 设置字体大小
              }
            },
            name: 'dB',
            nameTextStyle: {
              fontSize: 16,
              fontWeight: 'bold'
            },
            splitLine: {
              show: true
            }
          },
          series: [
            {
              name: '噪音分贝值',
              data: newData.data,
              type: 'line',
              showSymbol: false,
              // label: {
              //   show: true, // 显示标签
              //   position: 'top', // 标签位置，可以是 'top', 'insideTop', 'inside', 'insideBottom' 等
              //   color: 'white', // 标签颜色
              //   fontSize: 16, // 字体大小
              // },
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
