<template>
  <div id="info" ref="appRef">
    <div class="bg">
      <div class="info-body">

        <MyHeader :title=title></MyHeader>

        <div class="body-box">
          <!-- 顶部数据 -->
          <div class="top-box">
            <dv-border-box-12>
              <top :latest_info="latest_info"/>
            </dv-border-box-12>
          </div>
          <!--主要数据-->
          <div class="content-box">

            <div class="left-box">
              <dv-border-box-12>
                <left :noise="noise" @select-change="handleNoiseSelectChange"/>
              </dv-border-box-12>
            </div>

            <div class="mid-box">
              <div class="mid-top-box">
                <dv-border-box-12>
                  <midTop :safe="safe"/>
                </dv-border-box-12>
              </div>
              <div class="mid-bottom-box">
                <dv-border-box-12>
                  <midBottom :env="env" @select-change="handleEnvSelectChange"/>
                </dv-border-box-12>
              </div>
            </div>

            <div class="right-box">
              <dv-border-box-12>
                <right :smell="smell" @select-change="handleSmellSelectChange"/>
              </dv-border-box-12>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import drawMixin from "../../utils/drawMixin";
import MyHeader from "../../components/header/myHeader.vue";
import Top from "@/views/info/top.vue";
import Left from "@/views/info/left.vue";
import Right from "@/views/info/right.vue";
import MidBottom from "@/views/info/midBottom.vue";
import MidTop from "@/views/info/midTop.vue";
import info from "@/api/info";
import {getNowFormatDate, getOneMonthAgoDate, getSevenDaysAgoDate} from "@/utils/date";

export default defineComponent({
  name: "live",
  mixins: [drawMixin],
  components: {
    Top, Left, MidTop, MidBottom, Right,
    MyHeader
  },
  data() {
    return {
      title: "车间环境",
      env: [],
      smell: {},
      noise: {},
      safe: {},
      latest_info: {
        env: 0,
        noise: 0,
        smell: 0,
        safe: 0,
      },
      envSelectedValue: '30',
      smellSelectedValue: '30',
      noiseSelectedValue: '30',
    }
  },
  // mounted() {
  //   setInterval(() => {
  //     this.fetchData();
  //   }, 3000);
  // },
  created() {
    this.fetchData();
  },
  methods: {
    handleEnvSelectChange(value) {
      // 监听子组件的自定义事件，更新父组件中的值
      // console.log(value)
      this.envSelectedValue = value;
      this.fetchData();
    },
    handleSmellSelectChange(value) {
      // console.log(value)
      this.smellSelectedValue = value;
      this.fetchData();
    },
    handleNoiseSelectChange(value) {
      // console.log(value)
      this.noiseSelectedValue = value;
      this.fetchData();
    },

    async fetchData() {
      const now = getNowFormatDate()

      let envStartTime = getSevenDaysAgoDate(now);
      if (this.envSelectedValue == 30) {
        envStartTime = getOneMonthAgoDate(now);
      }
      let smellStartTime = getNowFormatDate(now);
      if (this.smellSelectedValue == 7) {
        smellStartTime = getSevenDaysAgoDate(now);
      } else if (this.smellSelectedValue == 30) {
        smellStartTime = getOneMonthAgoDate(now);
      }
      let noiseStartTime = getNowFormatDate(now);
      if (this.noiseSelectedValue == 7) {
        noiseStartTime = getSevenDaysAgoDate(now);
      } else if (this.noiseSelectedValue == 30) {
        noiseStartTime = getOneMonthAgoDate(now);
      }

      let Env = await info.getEnvInfoAnalysis(envStartTime, now, 1);
      let Smell = await info.getSmellInfoAnalysis(smellStartTime, now, 1);
      let Noise = await info.getNoiseInfoAnalysis(noiseStartTime, now, 1);
      let Safe = await info.getSafeInfoAnalysis('2023-1-1', now, 1);

      console.log(Env, Smell, Noise, Safe)
      if (Env.data.data.item !== null) {
        this.env = Env.data.data.item;
        this.latest_info.env = this.env[this.env.length - 1].level;
      } else {
        this.env = [];
        this.latest_info.env = 0;
      }
      if (Smell.data !== "") {
        this.smell = Smell.data.data;
        this.latest_info.smell = this.smell.smells[this.smell.smells.length - 1].value;
      } else {
        this.smell = {};
        this.latest_info.smell = 0;
      }
      if (Noise.data !== '') {
        this.noise = Noise.data.data;
        this.latest_info.noise = this.noise.noises[this.noise.noises.length - 1].value;
      } else {
        this.noise = {};
        this.latest_info.noise = 0;
      }
      if (Safe.data !== '') {
        this.safe = Safe.data.data;
        this.latest_info.safe = this.safe.count;
      } else {
        this.safe = {};
        this.latest_info.safe = 0;
      }

    },
  }
})
</script>

<style scoped lang="scss">
@import '../../assets/scss/info.scss';
</style>
